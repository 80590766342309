/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { ServerProps } from "../../config/server.config";
import BaseApp from "../../tools/components/general/BaseApp";
import BaseContainer from "../../tools/components/general/BaseContainer";
import {DatePicker, Icons} from 'tc-minibox'
import { date_swap, generateRandomCode, getChartLabels, getDataByDay, getMonthName, swap_modes } from "./transactions.utils";
import { icons_config } from "../../config/context.config";
import MainButton from "../../tools/components/general/MainButton";
import ChartTransactions from "./layouts/ChartTransactions";
import { DataType, TableForProps } from "tc-minibox/dist/types/table/index.table";
import {Table} from 'tc-minibox'
import { addRequestStatus, setLoaderStatus, set_popup_warning } from "../../store/global/actions.global";
import {ReactComponent as ChartBro} from '../../assets/svg/illustrations/charts-bro.svg' ; 
import { TransactionModel } from "./transactions.types";
import { v4 } from "uuid";
import { useTranslation } from "react-i18next";
import WithdrawalPayments from "./layouts/WithdrawalPayments";
import { useDispatch, useSelector } from "react-redux";
import { account_user } from "../../store/account/selector.account";




export interface TransactionsProps extends ServerProps  {

}

interface NewTransactionsState {
    code : string, 
    date : Date, 
    type : string, 
    eur:number,
    idr : number, 
    exchange_rate?:number,
    external : boolean,
}


interface TransactionManager {
    initialize : boolean, 
    mode : "all" | "year" | "month", 
    selected_withdrawal : TransactionModel | null,
}

export default function Transactions(props:TransactionsProps) {
    //GENERAL VARIABLES
    const dispatch = useDispatch()
    const currentDate = new Date();


    /*const example_data = [
        {
            "code": "BANKTR-ONLPKH",
            "type": "bank_transfer",
            "date": "2022-01-01T10:54:44.700175",
            "amount": 900,
            "exchange_rate": 0.7305555426418451
        },
        {
            "code": "WITHDR-YSQIVH",
            "type": "withdrawal",
            "date": "2022-01-02T10:54:44.700137",
            "amount": 634,
            "exchange_rate": 2.0122789776727963
        },
        {
            "code": "BANKTR-TUTWIA",
            "type": "bank_transfer",
            "date": "2022-01-06T10:54:44.700344",
            "amount": 1048,
            "exchange_rate": 1.954890038920823
        },
        {
            "code": "WITHDR-HKGYPD",
            "type": "withdrawal",
            "date": "2022-01-07T10:54:44.700928",
            "amount": 904,
            "exchange_rate": 0.6203462498214702
        },
        {
            "code": "BANKTR-VOLWSM",
            "type": "bank_transfer",
            "date": "2022-01-24T10:54:44.700217",
            "amount": 1400,
            "exchange_rate": 1.3104762381542385
        },
        {
            "code": "WITHDR-KMIVLQ",
            "type": "withdrawal",
            "date": "2022-02-08T10:54:44.700396",
            "amount": 874,
            "exchange_rate": 2.076988723438954
        },
        {
            "code": "BANKTR-OPCKLQ",
            "type": "bank_transfer",
            "date": "2022-02-08T10:54:44.700656",
            "amount": 739,
            "exchange_rate": 1.3682401352611784
        },
        {
            "code": "BANKTR-YZDQJD",
            "type": "bank_transfer",
            "date": "2022-02-11T10:54:44.700260",
            "amount": 175,
            "exchange_rate": 0.8358250372023539
        },
        {
            "code": "BANKTR-RIWUFI",
            "type": "bank_transfer",
            "date": "2022-02-25T10:54:44.700349",
            "amount": 983,
            "exchange_rate": 1.6976137038622832
        },
        {
            "code": "BANKTR-RYDFVW",
            "type": "bank_transfer",
            "date": "2022-03-01T10:54:44.700580",
            "amount": 618,
            "exchange_rate": 2.123830098449909
        },
        {
            "code": "BANKTR-GWRELK",
            "type": "bank_transfer",
            "date": "2022-03-06T10:54:44.700457",
            "amount": 142,
            "exchange_rate": 0.6127148164610055
        },
        {
            "code": "WITHDR-YFXFQK",
            "type": "withdrawal",
            "date": "2022-03-13T10:54:44.700406",
            "amount": 806,
            "exchange_rate": 1.8374349286654992
        },
        {
            "code": "WITHDR-FRYVAE",
            "type": "withdrawal",
            "date": "2022-03-14T10:54:44.700464",
            "amount": 1287,
            "exchange_rate": 2.0798016780119672
        },
        {
            "code": "BANKTR-IVBKIN",
            "type": "bank_transfer",
            "date": "2022-03-24T10:54:44.700523",
            "amount": 1187,
            "exchange_rate": 2.46025147361222
        },
        {
            "code": "WITHDR-IOTNBT",
            "type": "withdrawal",
            "date": "2022-04-11T10:54:44.700883",
            "amount": 334,
            "exchange_rate": 1.218797858715415
        },
        {
            "code": "WITHDR-CWLRXK",
            "type": "withdrawal",
            "date": "2022-04-26T10:54:44.700218",
            "amount": 1086,
            "exchange_rate": 0.5703525948136048
        },
        {
            "code": "BANKTR-BUWPFZ",
            "type": "bank_transfer",
            "date": "2022-05-20T10:54:44.700856",
            "amount": 265,
            "exchange_rate": 0.8363586738624393
        },
        {
            "code": "BANKTR-MMVWPO",
            "type": "bank_transfer",
            "date": "2022-05-21T10:54:44.700974",
            "amount": 285,
            "exchange_rate": 1.662410601157804
        },
        {
            "code": "WITHDR-TYFUQR",
            "type": "withdrawal",
            "date": "2022-06-02T10:54:44.700116",
            "amount": 587,
            "exchange_rate": 0.5673682012404713
        },
        {
            "code": "WITHDR-MFEZQW",
            "type": "withdrawal",
            "date": "2022-06-05T10:54:44.700508",
            "amount": 1482,
            "exchange_rate": 1.0040043282983016
        },
        {
            "code": "BANKTR-WFQTRE",
            "type": "bank_transfer",
            "date": "2022-06-10T10:54:44.700200",
            "amount": 637,
            "exchange_rate": 0.9168863416488975
        },
        {
            "code": "BANKTR-ZSKOMH",
            "type": "bank_transfer",
            "date": "2022-06-17T10:54:44.700545",
            "amount": 443,
            "exchange_rate": 2.028692304813262
        },
        {
            "code": "WITHDR-LKDUHZ",
            "type": "withdrawal",
            "date": "2022-06-19T10:54:44.700568",
            "amount": 671,
            "exchange_rate": 1.558013373207552
        },
        {
            "code": "WITHDR-MCQGMW",
            "type": "withdrawal",
            "date": "2022-07-05T10:54:44.700169",
            "amount": 1412,
            "exchange_rate": 1.7818099542758576
        },
        {
            "code": "BANKTR-PHGBIQ",
            "type": "bank_transfer",
            "date": "2022-07-14T10:54:44.700326",
            "amount": 372,
            "exchange_rate": 1.8359048975876096
        },
        {
            "code": "BANKTR-PJKNAA",
            "type": "bank_transfer",
            "date": "2022-08-08T10:54:44.700610",
            "amount": 1092,
            "exchange_rate": 1.769036086264753
        },
        {
            "code": "WITHDR-PXJKIP",
            "type": "withdrawal",
            "date": "2022-08-12T10:54:44.700846",
            "amount": 694,
            "exchange_rate": 0.8726565662330927
        },
        {
            "code": "WITHDR-AYVFYJ",
            "type": "withdrawal",
            "date": "2022-08-14T10:54:44.700293",
            "amount": 1380,
            "exchange_rate": 2.3415827713989668
        },
        {
            "code": "WITHDR-LWSTRM",
            "type": "withdrawal",
            "date": "2022-08-20T10:54:44.700341",
            "amount": 783,
            "exchange_rate": 1.7861127518176054
        },
        {
            "code": "WITHDR-LDVLBO",
            "type": "withdrawal",
            "date": "2022-09-02T10:54:44.700769",
            "amount": 1181,
            "exchange_rate": 0.9785726447027006
        },
        {
            "code": "WITHDR-HOQVJE",
            "type": "withdrawal",
            "date": "2022-09-03T10:54:44.700837",
            "amount": 980,
            "exchange_rate": 2.035352877107665
        },
        {
            "code": "BANKTR-PPUYAP",
            "type": "bank_transfer",
            "date": "2022-09-13T10:54:44.700742",
            "amount": 1444,
            "exchange_rate": 1.2372878668164886
        },
        {
            "code": "BANKTR-KINMKO",
            "type": "bank_transfer",
            "date": "2022-09-27T10:54:44.700849",
            "amount": 1194,
            "exchange_rate": 1.3938657208728866
        },
        {
            "code": "WITHDR-HSVXXL",
            "type": "withdrawal",
            "date": "2022-10-01T10:54:44.700489",
            "amount": 587,
            "exchange_rate": 1.726327103178086
        },
        {
            "code": "BANKTR-NEEVWP",
            "type": "bank_transfer",
            "date": "2022-10-02T10:54:44.700251",
            "amount": 300,
            "exchange_rate": 2.1400066535510764
        },
        {
            "code": "WITHDR-NKRLYY",
            "type": "withdrawal",
            "date": "2022-10-11T10:54:44.700816",
            "amount": 1473,
            "exchange_rate": 0.9705351540844576
        },
        {
            "code": "BANKTR-ZKCQJB",
            "type": "bank_transfer",
            "date": "2022-10-12T10:54:44.700789",
            "amount": 178,
            "exchange_rate": 1.4350274617149934
        },
        {
            "code": "BANKTR-XOGEMR",
            "type": "bank_transfer",
            "date": "2022-11-01T10:54:44.700660",
            "amount": 955,
            "exchange_rate": 1.159156183887504
        },
        {
            "code": "WITHDR-LSHVAS",
            "type": "withdrawal",
            "date": "2022-11-06T10:54:44.700521",
            "amount": 1015,
            "exchange_rate": 1.4143034691477256
        },
        {
            "code": "WITHDR-ICCINR",
            "type": "withdrawal",
            "date": "2022-11-17T10:54:44.700387",
            "amount": 887,
            "exchange_rate": 2.160953020308842
        },
        {
            "code": "WITHDR-ZVQARD",
            "type": "withdrawal",
            "date": "2022-11-17T10:54:44.700558",
            "amount": 717,
            "exchange_rate": 2.3164088920209625
        },
        {
            "code": "WITHDR-QMUOUM",
            "type": "withdrawal",
            "date": "2022-12-11T10:54:44.700746",
            "amount": 1117,
            "exchange_rate": 0.6585554624533034
        },
        {
            "code": "WITHDR-RAWSOD",
            "type": "withdrawal",
            "date": "2022-12-20T10:54:44.700459",
            "amount": 263,
            "exchange_rate": 1.5511714962953578
        },
        {
            "code": "WITHDR-PKWVRM",
            "type": "withdrawal",
            "date": "2023-01-03T10:54:44.700321",
            "amount": 453,
            "exchange_rate": 1.600945900134769
        },
        {
            "code": "BANKTR-CLAKMN",
            "type": "bank_transfer",
            "date": "2023-01-10T10:54:44.700882",
            "amount": 1403,
            "exchange_rate": 0.6834896556214971
        },
        {
            "code": "BANKTR-CZLOKU",
            "type": "bank_transfer",
            "date": "2023-01-20T10:54:44.700874",
            "amount": 1292,
            "exchange_rate": 0.5587350963411151
        },
        {
            "code": "WITHDR-RHDWQJ",
            "type": "withdrawal",
            "date": "2023-01-22T10:54:44.700843",
            "amount": 197,
            "exchange_rate": 0.8755672825728547
        },
        {
            "code": "BANKTR-OPUBHO",
            "type": "bank_transfer",
            "date": "2023-02-01T10:54:44.700714",
            "amount": 937,
            "exchange_rate": 2.16579032455025
        },
        {
            "code": "WITHDR-TLJEBZ",
            "type": "withdrawal",
            "date": "2023-02-06T10:54:44.700400",
            "amount": 275,
            "exchange_rate": 1.4455813854235295
        },
        {
            "code": "BANKTR-CPTXWS",
            "type": "bank_transfer",
            "date": "2023-02-22T10:54:44.700659",
            "amount": 1279,
            "exchange_rate": 2.2298162930525756
        },
        {
            "code": "WITHDR-QBZCQH",
            "type": "withdrawal",
            "date": "2023-02-26T10:54:44.700475",
            "amount": 607,
            "exchange_rate": 1.779726785948037
        },
        {
            "code": "WITHDR-MOXYQQ",
            "type": "withdrawal",
            "date": "2023-03-11T10:54:44.700385",
            "amount": 812,
            "exchange_rate": 1.9511217798584417
        },
        {
            "code": "WITHDR-NPQMDX",
            "type": "withdrawal",
            "date": "2023-03-26T10:54:44.700528",
            "amount": 1432,
            "exchange_rate": 2.437695171502282
        },
        {
            "code": "WITHDR-LGWCMY",
            "type": "withdrawal",
            "date": "2023-04-07T10:54:44.700609",
            "amount": 652,
            "exchange_rate": 2.231937076700503
        },
        {
            "code": "BANKTR-CDYRLP",
            "type": "bank_transfer",
            "date": "2023-04-07T10:54:44.700981",
            "amount": 1268,
            "exchange_rate": 1.6912085870125964
        },
        {
            "code": "WITHDR-GDSJZE",
            "type": "withdrawal",
            "date": "2023-04-22T10:54:44.700513",
            "amount": 1043,
            "exchange_rate": 1.6909391069601254
        },
        {
            "code": "WITHDR-JVYNVR",
            "type": "withdrawal",
            "date": "2023-05-14T10:54:44.700675",
            "amount": 365,
            "exchange_rate": 1.4534633719129397
        },
        {
            "code": "WITHDR-KFHERX",
            "type": "withdrawal",
            "date": "2023-05-19T10:54:44.700725",
            "amount": 470,
            "exchange_rate": 1.0692080139493816
        },
        {
            "code": "BANKTR-NDVNVY",
            "type": "bank_transfer",
            "date": "2023-05-21T10:54:44.700912",
            "amount": 389,
            "exchange_rate": 1.9070697272159354
        },
        {
            "code": "WITHDR-VSDWDT",
            "type": "withdrawal",
            "date": "2023-05-23T10:54:44.700916",
            "amount": 1017,
            "exchange_rate": 1.484992513176088
        },
        {
            "code": "WITHDR-ATEAYT",
            "type": "withdrawal",
            "date": "2023-05-25T10:54:44.700548",
            "amount": 1003,
            "exchange_rate": 2.2338766650300177
        },
        {
            "code": "BANKTR-HVHMUV",
            "type": "bank_transfer",
            "date": "2023-06-03T10:54:44.700879",
            "amount": 930,
            "exchange_rate": 2.4261802269994113
        },
        {
            "code": "WITHDR-OBCDGT",
            "type": "withdrawal",
            "date": "2023-06-20T10:54:44.700628",
            "amount": 956,
            "exchange_rate": 1.6984941456367815
        },
        {
            "code": "BANKTR-UFDWSV",
            "type": "bank_transfer",
            "date": "2023-07-03T10:54:44.700546",
            "amount": 1080,
            "exchange_rate": 1.5858319896265556
        },
        {
            "code": "BANKTR-TGMMFM",
            "type": "bank_transfer",
            "date": "2023-07-19T10:54:44.700501",
            "amount": 1357,
            "exchange_rate": 1.3559988347674676
        },
        {
            "code": "WITHDR-SDKQPI",
            "type": "withdrawal",
            "date": "2023-07-23T10:54:44.700492",
            "amount": 850,
            "exchange_rate": 1.51348179218214
        },
        {
            "code": "WITHDR-VWEIDJ",
            "type": "withdrawal",
            "date": "2023-08-02T10:54:44.700596",
            "amount": 893,
            "exchange_rate": 1.0224667346712293
        },
        {
            "code": "BANKTR-LSYQCW",
            "type": "bank_transfer",
            "date": "2023-08-02T10:54:44.700730",
            "amount": 409,
            "exchange_rate": 0.5894589068896254
        },
        {
            "code": "BANKTR-ISNHKW",
            "type": "bank_transfer",
            "date": "2023-08-09T10:54:44.700447",
            "amount": 360,
            "exchange_rate": 1.9092604165735194
        },
        {
            "code": "WITHDR-ZYQDZV",
            "type": "withdrawal",
            "date": "2023-08-18T10:54:44.700663",
            "amount": 305,
            "exchange_rate": 0.5640490071317221
        },
        {
            "code": "BANKTR-RLAJXM",
            "type": "bank_transfer",
            "date": "2023-08-27T10:54:44.700487",
            "amount": 340,
            "exchange_rate": 2.1114537429699065
        },
        {
            "code": "BANKTR-MYHYLU",
            "type": "bank_transfer",
            "date": "2023-09-03T10:54:44.700766",
            "amount": 1258,
            "exchange_rate": 1.0030454830330848
        },
        {
            "code": "BANKTR-RKMZIC",
            "type": "bank_transfer",
            "date": "2023-09-12T10:54:44.700844",
            "amount": 992,
            "exchange_rate": 2.0752176062143524
        },
        {
            "code": "WITHDR-XVNOHG",
            "type": "withdrawal",
            "date": "2023-09-18T10:54:44.700662",
            "amount": 638,
            "exchange_rate": 1.5739639571842565
        },
        {
            "code": "WITHDR-ONNNWJ",
            "type": "withdrawal",
            "date": "2023-09-27T10:54:44.700821",
            "amount": 103,
            "exchange_rate": 0.6621786334739366
        },
        {
            "code": "BANKTR-AOPXRA",
            "type": "bank_transfer",
            "date": "2023-10-08T10:54:44.700494",
            "amount": 1256,
            "exchange_rate": 1.120374671808534
        },
        {
            "code": "BANKTR-YEXVGW",
            "type": "bank_transfer",
            "date": "2023-10-14T10:54:44.700663",
            "amount": 496,
            "exchange_rate": 1.6681706431827865
        },
        {
            "code": "WITHDR-DAWNXV",
            "type": "withdrawal",
            "date": "2023-10-19T10:54:44.700414",
            "amount": 325,
            "exchange_rate": 2.3653675943095003
        },
        {
            "code": "BANKTR-YFZFTQ",
            "type": "bank_transfer",
            "date": "2023-10-19T10:54:44.700717",
            "amount": 303,
            "exchange_rate": 0.7250046800697103
        },
        {
            "code": "WITHDR-JXEJBF",
            "type": "withdrawal",
            "date": "2023-10-24T10:54:44.700461",
            "amount": 570,
            "exchange_rate": 0.511978307649297
        },
        {
            "code": "BANKTR-KLTWKZ",
            "type": "bank_transfer",
            "date": "2023-11-12T10:54:44.700467",
            "amount": 803,
            "exchange_rate": 1.3354105168908235
        },
        {
            "code": "WITHDR-FQPSPN",
            "type": "withdrawal",
            "date": "2023-11-22T10:54:44.700540",
            "amount": 917,
            "exchange_rate": 2.278275950604147
        },
        {
            "code": "WITHDR-WQHBAY",
            "type": "withdrawal",
            "date": "2023-12-23T10:54:44.700760",
            "amount": 823,
            "exchange_rate": 0.9216146001975174
        },
        {
            "code": "BANKTR-RTTMAJ",
            "type": "bank_transfer",
            "date": "2023-12-25T10:54:44.700290",
            "amount": 1166,
            "exchange_rate": 0.7309725474159654
        },
        {
            "code": "BANKTR-ZPMZTH",
            "type": "bank_transfer",
            "date": "2024-01-10T10:54:44.700864",
            "amount": 518,
            "exchange_rate": 1.8136549247386378
        },
        {
            "code": "WITHDR-WOBDAJ",
            "type": "withdrawal",
            "date": "2024-01-24T10:54:44.700132",
            "amount": 1282,
            "exchange_rate": 1.2492276635988335
        },
        {
            "code": "WITHDR-UYUDBI",
            "type": "withdrawal",
            "date": "2024-01-24T10:54:44.700218",
            "amount": 339,
            "exchange_rate": 2.009688672072288
        },
        {
            "code": "WITHDR-IGYAMN",
            "type": "withdrawal",
            "date": "2024-02-02T10:54:44.700275",
            "amount": 523,
            "exchange_rate": 1.558528635490352
        },
        {
            "code": "WITHDR-KNRSQN",
            "type": "withdrawal",
            "date": "2024-02-15T10:54:44.700360",
            "amount": 392,
            "exchange_rate": 2.1632821789191468
        },
        {
            "code": "BANKTR-VDTARJ",
            "type": "bank_transfer",
            "date": "2024-02-16T10:54:44.700535",
            "amount": 164,
            "exchange_rate": 2.116759755044076
        },
        {
            "code": "BANKTR-KUWILA",
            "type": "bank_transfer",
            "date": "2024-02-26T10:54:44.700580",
            "amount": 725,
            "exchange_rate": 0.6095179487390641
        },
        {
            "code": "BANKTR-JHGOKE",
            "type": "bank_transfer",
            "date": "2024-03-09T10:54:44.700289",
            "amount": 1214,
            "exchange_rate": 1.8706374637270016
        },
        {
            "code": "WITHDR-AQFQMR",
            "type": "withdrawal",
            "date": "2024-03-16T10:54:44.700275",
            "amount": 335,
            "exchange_rate": 2.3885584928907466
        },
        {
            "code": "BANKTR-DZFHPK",
            "type": "bank_transfer",
            "date": "2024-03-18T10:54:44.700580",
            "amount": 932,
            "exchange_rate": 2.2150537145500335
        },
        {
            "code": "BANKTR-ZXNMEX",
            "type": "bank_transfer",
            "date": "2024-03-27T10:54:44.700587",
            "amount": 740,
            "exchange_rate": 0.580741271694128
        },
        {
            "code": "BANKTR-XBZNPG",
            "type": "bank_transfer",
            "date": "2024-04-13T10:54:44.700371",
            "amount": 124,
            "exchange_rate": 2.1263370516726727
        },
        {
            "code": "WITHDR-HKDUSU",
            "type": "withdrawal",
            "date": "2024-04-19T10:54:44.700404",
            "amount": 1477,
            "exchange_rate": 1.764865938860892
        },
        {
            "code": "WITHDR-PKZLVU",
            "type": "withdrawal",
            "date": "2024-04-22T10:54:44.700507",
            "amount": 221,
            "exchange_rate": 1.1352110828583453
        },
        {
            "code": "WITHDR-TNWAGU",
            "type": "withdrawal",
            "date": "2024-05-11T10:54:44.700392",
            "amount": 453,
            "exchange_rate": 1.2255031181462805
        },
        {
            "code": "WITHDR-KPZJDU",
            "type": "withdrawal",
            "date": "2024-05-12T10:54:44.700999",
            "amount": 929,
            "exchange_rate": 1.7920420869415703
        },
        {
            "code": "WITHDR-LDYPBH",
            "type": "withdrawal",
            "date": "2024-05-16T10:54:44.700234",
            "amount": 1321,
            "exchange_rate": 2.1831475661373094
        },
        {
            "code": "WITHDR-UFMPOV",
            "type": "withdrawal",
            "date": "2024-05-18T10:54:44.700192",
            "amount": 224,
            "exchange_rate": 2.20148684801477
        },
        {
            "code": "WITHDR-WRRYFE",
            "type": "withdrawal",
            "date": "2024-05-18T10:54:44.700705",
            "amount": 624,
            "exchange_rate": 1.1254907544605386
        },
        {
            "code": "WITHDR-VGXJWG",
            "type": "withdrawal",
            "date": "2024-06-01T10:54:44.700456",
            "amount": 292,
            "exchange_rate": 0.5285494239157666
        },
        {
            "code": "WITHDR-LLUZHA",
            "type": "withdrawal",
            "date": "2024-06-22T10:54:44.700142",
            "amount": 1031,
            "exchange_rate": 1.842611601070405
        },
        {
            "code": "WITHDR-WGPFKZ",
            "type": "withdrawal",
            "date": "2024-07-16T10:54:44.700115",
            "amount": 748,
            "exchange_rate": 1.4661835605164628
        },
        {
            "code": "WITHDR-KCLHIF",
            "type": "withdrawal",
            "date": "2024-07-24T10:54:44.700798",
            "amount": 1474,
            "exchange_rate": 1.6644405603463734
        },
        {
            "code": "BANKTR-TVBBAJ",
            "type": "bank_transfer",
            "date": "2024-08-15T10:54:44.700451",
            "amount": 1475,
            "exchange_rate": 0.8111217120879965
        },
        {
            "code": "WITHDR-EPVJJW",
            "type": "withdrawal",
            "date": "2024-08-20T10:54:44.700163",
            "amount": 932,
            "exchange_rate": 2.372752058411535
        },
        {
            "code": "BANKTR-GTRBSJ",
            "type": "bank_transfer",
            "date": "2024-09-15T10:54:44.700130",
            "amount": 1005,
            "exchange_rate": 1.6544437750834993
        },
        {
            "code": "WITHDR-PTJCCJ",
            "type": "withdrawal",
            "date": "2024-09-20T10:54:44.700835",
            "amount": 460,
            "exchange_rate": 1.510172119367468
        },
        {
            "code": "WITHDR-CKXZOX",
            "type": "withdrawal",
            "date": "2024-09-28T10:54:44.700100",
            "amount": 161,
            "exchange_rate": 1.6596410716022916
        },
        {
            "code": "WITHDR-CSDUSE",
            "type": "withdrawal",
            "date": "2024-09-28T10:54:44.700726",
            "amount": 1147,
            "exchange_rate": 0.8800796695195143
        },
        {
            "code": "WITHDR-ORCYFC",
            "type": "withdrawal",
            "date": "2024-10-11T10:54:44.700331",
            "amount": 1050,
            "exchange_rate": 2.1229979555491756
        },
        {
            "code": "BANKTR-MUUGXB",
            "type": "bank_transfer",
            "date": "2024-10-23T10:54:44.700384",
            "amount": 1258,
            "exchange_rate": 1.575953640484681
        },
        {
            "code": "WITHDR-EXHZBN",
            "type": "withdrawal",
            "date": "2024-11-01T10:54:44.700583",
            "amount": 1348,
            "exchange_rate": 1.549326524171916
        },
        {
            "code": "WITHDR-XPYLWN",
            "type": "withdrawal",
            "date": "2024-11-22T10:54:44.700550",
            "amount": 148,
            "exchange_rate": 1.770462168235826
        },
        {
            "code": "BANKTR-REWEIE",
            "type": "bank_transfer",
            "date": "2024-12-04T10:54:44.700535",
            "amount": 175,
            "exchange_rate": 0.6030563581759307
        },
        {
            "code": "WITHDR-EUPMSK",
            "type": "withdrawal",
            "date": "2024-12-15T10:54:44.700345",
            "amount": 866,
            "exchange_rate": 1.6011297963056286
        },
        {
            "code": "WITHDR-IFDAAJ",
            "type": "withdrawal",
            "date": "2024-12-18T10:54:44.700210",
            "amount": 793,
            "exchange_rate": 0.7832013114150229
        },
        {
            "code": "BANKTR-HNYODB",
            "type": "bank_transfer",
            "date": "2024-12-25T10:54:44.700202",
            "amount": 171,
            "exchange_rate": 1.73976152563862
        }
    ]*/
    
    // * STATES
    const {t, i18n} = useTranslation()
    const translate = (key:string) => t(`transactions.${key}`)
    const user_account = useSelector(account_user)
    const admin = user_account.infos.permission_type === "admin"

    //CONTROLLERS
    const [manager, setManager] = useState<TransactionManager>({
        initialize : false, 
        mode : "all", 
        selected_withdrawal : null
    })
    const [selectedDate, setSelectedDate] = useState<any>({
        day : currentDate.getDate(), 
        month : currentDate.getMonth(),
        year : currentDate.getFullYear(),
    });

    const [idChart, setIdChart] = useState<string>(v4())
    /*const [events, setEvents] = useState<any>({
        fullscreen : null, 
    })*/

    //DATA
    const [transactions, setTransactions] = useState<DataType>([])
    const [newTransaction, setNewTransaction] = useState<NewTransactionsState>({
        code : generateRandomCode(swap_modes[1].name.toUpperCase()), 
        date : new Date(),
        type : swap_modes[1].name, 
        eur : 1,
        idr : 1,
        external : false
    })


    // * VARIABLES
    const exchangeRateForEuro = newTransaction.idr / newTransaction.eur

    const pre_tbody = transactions.map(trans => {
        const date = new Date(trans.date)
        if(manager.mode === "year" && date.getFullYear() !== selectedDate.year) {
            return null
        }

        if(manager.mode === "month") {
            if(date.getFullYear() !== selectedDate.year) {
                return null
            }

            if(date.getMonth() !== selectedDate.month) {
                return null
            }
            return trans
        }

        return trans
        
    }).filter(trans => trans !== null)

    // Sum up the amounts for bank transfers using reduce
    const totalBankTransfer = pre_tbody.filter((transaction) => transaction.type === 'bank_transfer').map(transaction => transaction.amount).reduce((acc, curr) => acc + curr, 0)    
    // Sum up the amounts for withdrawals using reduce
    const totalWithdrawal = pre_tbody.filter((transaction) => transaction.type === 'withdrawal').map(transaction => transaction.amount).reduce((acc, curr) => acc + curr, 0)

    const tbody = pre_tbody.map((transaction) => {
        const spent = (transaction.type === 'withdrawal' && transaction.payments) ? transaction.payments.map((el:any) => el.amount).reduce((a:number, b:number) => a + b, 0) : 0
        return {
            ...transaction, 
            type_text : translate(`${swap_modes.find(mode => mode.name === transaction.type)?.text}`), 
            amount : `${transaction.amount} €`,
            exchange_rate : transaction.type === "withdrawal" ? `${transaction.exchange_rate.toFixed(2)} IDR` : "N/A",
            affected : transaction.type === "withdrawal" ? `${spent.toFixed(2)} €` : "N/A",
            unaffected : transaction.type === "withdrawal" ? `${(transaction.amount - spent).toFixed(2)} €` : "N/A",
            affected_idr : transaction.type === "withdrawal" ? `${(spent * transaction.exchange_rate).toFixed(0)}` : "N/A",
            unaffected_idr : transaction.type === "withdrawal" ? `${((transaction.amount - spent) * transaction.exchange_rate).toFixed(0)}` : "N/A",
        }
    }).sort((a, b) => {
        const dateA = new Date(a.date).getTime()
        const dateB = new Date(b.date).getTime()

        return dateB - dateA
    })

    //FUNCTIONS
    const handleChangeInput = (e:React.ChangeEvent<HTMLInputElement>, reg:string|RegExp = '') => {
        const value = e.target.value.replaceAll(',', ".")
        
        if (reg instanceof RegExp && !reg.test(value)) {
            // La saisie de l'utilisateur ne correspond pas à l'expression régulière,
            // vous pouvez gérer cela ici (par exemple, afficher un message d'erreur).
            return;
        }

        setNewTransaction(state => {
            return {
                ...state, 
                [e.target.name] : value
            }
        
        })
    }

    const handleSubmit = () => {
        if(newTransaction.eur === 0 || newTransaction.idr === 0) {
            return dispatch(addRequestStatus({
                status : true, 
                message : "Le montant doit être supérieur à 0"
            }))
        }
        const data = {
            id_inv : null, 
            id_trans : null, 
            code : newTransaction.code,
            type : newTransaction.type,
            date : `${new Date(newTransaction.date).toISOString()}`,
            amount : parseFloat(`${newTransaction.eur}`), 
            exchange_rate : newTransaction.type === "withdrawal" ? parseFloat(exchangeRateForEuro.toFixed(2)) : parseFloat(`${newTransaction.eur}`),
            url : null
        }
        dispatch(setLoaderStatus({status : true}))
        return props.server.post('/transactions/add', data) 
        .then(res => {
            dispatch(setLoaderStatus({status : false}))
            dispatch(addRequestStatus(res.data))

            setNewTransaction((state:any) => {
                return {
                    ...state, 
                    code : generateRandomCode(swap_modes[1].name.toUpperCase()), 
                    date : new Date(),
                    eur : 1,
                    idr : 1,
                    external : false
                }
            })

            setTransactions(state => {
                return [
                    ...state, 
                    res.data.data
                ]
            })
            setIdChart(v4())


            //props.handleClose()
            //props.handleAdd(res.data.data)
        })
        .catch(err => {
              dispatch(addRequestStatus({
                status : err.response.data.status, 
                message : t(err.response.data.message)
            }))
            dispatch(setLoaderStatus({status : false}))
        })





    }

    const handlePrevMonth = () => {
        setSelectedDate((state:any) => {
            // Si le mois actuel est janvier (indice 0), on passe à décembre (indice 11)
            // de l'année précédente.
            if (state.month === 0) {
                return {
                    ...state,
                    month: 11, // Décembre, car les mois sont indexés à partir de 0
                    year: state.year - 1 // Décrémenter l'année
                };
            } else {
                // Sinon, on décrémente juste le mois
                return {
                    ...state,
                    month: state.month - 1
                };
            }
        });
    }

    const handleNextMonth = () => {
        setSelectedDate((state:any) => {
            // Si le mois actuel est décembre (indice 11), on passe à janvier (indice 0)
            // de l'année suivante.
            if (state.month === 11) {
                return {
                    ...state,
                    month: 0, // Janvier, car les mois sont indexés à partir de 0
                    year: state.year + 1 // Incrémenter l'année
                };
            } else {
                // Sinon, on incrémente juste le mois
                return {
                    ...state,
                    month: state.month + 1
                };
            }
        });
    }

    const handleDoubleClick = (tr:any) => {

        if(tr.type === "withdrawal") {
           return setManager(state => {return {...state, selected_withdrawal : tr.id}})
        }

        return dispatch(addRequestStatus({
            status : false,
            message : "Veuillez sélectionner un retrait"
        }))
    }

    const handleDelete = (e:any) => {

        if(e.find((el:any) => el.payments.length > 0)) {
            return dispatch(addRequestStatus({
                status : false,
                message : t("transactions.status.cant-delete-with-payments")
                //Veuillez supprimer les paiements avant de supprimer le retrait
            }))
        }

        const data = e.map((el:any )=> el.id)
        dispatch(setLoaderStatus({status : true, message : ""}))
        props.server.post('/transactions/delete', {ids : data})
        .then((res:any) => {
            //props.setTransactions((state:any) => {return [...state, res.data.data]})
            dispatch(addRequestStatus(res.data))
            setTransactions([])
            setManager(state => {return {...state, initialize : false}})
            dispatch(setLoaderStatus({status : false, message : ""}))
        })
        .catch(err => {
              dispatch(addRequestStatus({
                status : err.response.data.status, 
                message : t(err.response.data.message)
            }))
            dispatch(setLoaderStatus({status : false}))
        })
    }

    //COMPONENT CONFIG
    const table_config:TableForProps = {
        height : 30, 
        editing : false, 
        icons : icons_config, 
        config : [
            {
                info : {
                    text : "Date", 
                    name : "date",
                    type : "date"
                }, 
                style : {
                    width : `calc(${100/9}%)`, 
                    tbody : {
                        textAlign : "center"
                    }
                }
            }, 
            {
                info : {
                    text : "Code", 
                    name : "code",
                }, 
                style : {
                    width : `calc(${100/9}%)`, 
                    tbody : {
                        textAlign : "center"
                    }
                }
            }, 
            {
                info : {
                    text : "Type", 
                    name : "type_text",
                }, 
                style : {
                    width : `calc(${100/9}%)`, 
                    tbody : {
                        textAlign : "center"
                    }
                }
            }, 
            {
                info : {
                    text : translate("amount"), 
                    name : "amount",
                }, 
                style : {
                    width : `calc(${100/9}%)`, 
                    tbody : {
                        textAlign : "end"
                    }
                }
            }, 
            {
                info : {
                    text : translate("rate"), 
                    name : "exchange_rate",
                }, 
                style : {
                    width : `calc(${100/9}%)`, 
                    tbody : {
                        textAlign : "end"
                    }
                }
            }, 
            {
                info : {
                    text : `${translate("affected")} EUR`, 
                    name : "affected",
                }, 
                style : {
                    width : `calc(${100/9}%)`, 
                    tbody : {
                        textAlign : "end"
                    }
                }
            }, 
            {
                info : {
                    text : `${translate("unaffected")} EUR`, 
                    name : "unaffected",
                }, 
                style : {
                    width : `calc(${100/9}%)`, 
                    tbody : {
                        textAlign : "end"
                    }
                }
            }, 
            {
                info : {
                    text : `${translate("affected")} IDR`, 
                    name : "affected_idr",
                }, 
                style : {
                    width : `calc(${100/9}%)`, 
                    tbody : {
                        textAlign : "end"
                    }
                }
            }, 
            {
                info : {
                    text : `${translate("unaffected")} IDR`, 
                    name : "unaffected_idr",
                }, 
                style : {
                    width : `calc(${100/9}%)`, 
                    tbody : {
                        textAlign : "end"
                    }
                }
            }, 
        ], 
        data : tbody,
        setData : setTransactions,
        header : {
            buttons : {
                include : admin ? ["delete"] : [], 
                actions : {
                    /*add: () => setManager(state => {return {...state, add : true}}),  */
                    delete : (e:any) => {
                        dispatch(set_popup_warning({
                            status : true, 
                            text : "Êtes-vous sûr de vouloir supprimer ?", 
                            handleYes : () => handleDelete(e)
                        }))

                    },

                }
            }
        }, 
        handleDoubleClick : (tr:any) => handleDoubleClick(tr)
    }

    // * USE EFFECTS

    //INITIALIZE
    useEffect(() => {
        if(!manager.initialize) {
            dispatch(setLoaderStatus({status : true, message : "Chargement des transactions en cours..."}))
            props.server.post('/transactions/initialize', {types : ["withdrawal", "bank_transfer"]})
            .then((res:any) => {
                setManager(state => { return {...state, initialize : true}})
                setTransactions(res.data.data.map((el:any) => {return {...el, payments : JSON.parse(el.payments)}}))
                dispatch(setLoaderStatus({status : false, message : ""}))
            })
            .catch(err => {
                  dispatch(addRequestStatus({
                status : err.response.data.status, 
                message : t(err.response.data.message)
            }))
                dispatch(setLoaderStatus({status : false}))
            })
        }
    }, [manager.initialize])

    return (
        <React.Fragment>
            {
                manager.selected_withdrawal !== null ? 
                    <WithdrawalPayments 
                        server={props.server}
                        title={`${translate("withdrawal")} - ${transactions.find((el:any) => el.id === manager.selected_withdrawal)?.code}`}
                        payments={transactions.find((el:any) => el.id === manager.selected_withdrawal)?.payments !== undefined ? transactions.find((el:any) => el.id === manager.selected_withdrawal)?.payments : []}
                        setPayments={(payments:any) => setTransactions((state:any) => {return{...state, payments : payments}})}
                        handleClose = {() => setManager(state => {return {...state, selected_withdrawal : null}})}
                    />
                : 
                    null
            }
            <BaseApp>
                <BaseContainer nameClass="transactions">
                    <div className = "transactions__dateswap">
                            {
                                manager.mode === "month" ? 
                                    <div className="arrows">
                                        <div 
                                            onClick = {() => handlePrevMonth()}
                                            className="arrows--left"
                                        >
                                            <Icons name = "left" mode = "duotone" color = "#b3b7bc" />
                                        </div>
                                        
                                        <p>{`${getMonthName(new Date(`${selectedDate.year}-${selectedDate.month + 1}-${selectedDate.day}`), i18n.language as "en"|"fr")} ${selectedDate.year}`}</p>
                                        <div 
                                            onClick = {() => handleNextMonth()}
                                            className="arrow--right"
                                        >
                                            <Icons name = "right" mode = "duotone" color = "#b3b7bc" />
                                        </div>
                                    </div>
                                : null
                            }

                            <div className="swap_to">
                            {
                                date_swap.map((date, index) => (
                                    <div 
                                        key={date.name} 
                                        onClick = {() => setManager((state:any)=> {return {...state, mode : date.name}})}

                                        className={`transactions_dateswap--item ${manager.mode === date.name ? "active" : ""}`}
                                    >
                                        <p>{translate(date.text)}</p>
                                    </div>
                                ))
                            }
                            </div>
                    </div>
                    
                    <div className="transactions__left-column">
                        <div id = "new-transaction">
                            <div className="swap_to">
                                <p>{translate("swap-to")}: </p>
                                <div className="swap_to--container">
                                    {
                                        swap_modes.map((mode, index) => (
                                            <div 
                                                key={mode.name} 
                                                onClick = {() => setNewTransaction(state => {
                                                    return {
                                                        ...state, 
                                                        code : generateRandomCode(mode.name.toUpperCase()),
                                                        type : mode.name
                                                    }
                                                })}
                                                className={`swap_to--item ${newTransaction.type === mode.name ? "active" : ""}`}
                                            >
                                                
                                                <p>{translate(mode.text)}</p>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>

                            <div id = "code">
                                <p>Code</p>
                                <p>{newTransaction.code}</p>
                            </div>

                            <div id="date">
                                <label htmlFor = "date">Date</label>
                                <DatePicker
                                    selected={new Date(newTransaction.date)} 
                                    dateFormat="dd/MM/yyyy"
                                    onChange = {(e:any) => setNewTransaction(state => {return{...state, date : e}})}
                                    locale="fr"       
                                />
                            </div>
                            
                            <label htmlFor = "amount">{translate("amount")}</label>
                            <div className="currencies_values">

                                <div>
                                    <input 
                                        type="text" 
                                        name = "eur"
                                        value = {newTransaction.eur}
                                        onChange = {(e:React.ChangeEvent<HTMLInputElement>) => {
                                            handleChangeInput(e, /^\d*\.?\d*$/)
                                        }}
                                        //onBlur = {() => handleBlur("unit_rp_amount")}
                                    />
                                </div>
                                {
                                    newTransaction.type === "withdrawal" ? 
                                        <React.Fragment>
                                            <Icons name = "arrow-down-arrow-up" {...icons_config} />
                                            <div>
                                                <input 
                                                    type="text" 
                                                    name = "idr"
                                                    value = {newTransaction.idr}
                                                    onChange = {(e:React.ChangeEvent<HTMLInputElement>) => {
                                                        handleChangeInput(e, /^\d*\.?\d*$/)
                                                    }}
                                                    //onBlur = {() => handleBlur("unit_rp_amount")}
                                                />
                                            </div>
                                        <div className="exchangeRateForEuro">
                                                1 EUR = {exchangeRateForEuro.toFixed(2)} IDR
                                            </div>

                                        </React.Fragment>
                                    : 
                                        null
                                }
                            </div>

                            <MainButton
                                handleClick={handleSubmit}
                            >
                                {t("commons.save")}
                            </MainButton>
                        </div>

                        <div id = "summary">
                            <h1>{manager.mode === "year" ? translate("yearly-financial-movements") : manager.mode === "month" ? translate("monthly-financial-movements") : translate("movements")}</h1>
                            <h2>{manager.mode === "year" ? selectedDate.year : manager.mode === "month" ? `${getMonthName(new Date(`${selectedDate.year}-${selectedDate.month + 1}-${selectedDate.day}`), i18n.language as "en"|"fr")} ${selectedDate.year}` : ""}</h2>
                            <div className="summary__progressBar">
                                <div className="amount">
                                    <p>{translate("spent")}</p>
                                    <p>{totalWithdrawal.toFixed(2)}</p>
                                </div>
                                <div className="progress-bar-background">
                                    <div 
                                        className="progress-bar-foreground"
                                        style = {{width : `${(totalWithdrawal * 100 / totalBankTransfer)}%`}}
                                    ></div>
                                </div>
                                <div className="amount">
                                    <p>{translate("deposit")}</p>
                                    <p>{totalBankTransfer.toFixed(2)}</p>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="transactions__container">
                        {
                            transactions.length > 0 ? 
                                <ChartTransactions
                                identification={idChart}
                                chartLabels = {getChartLabels(transactions, manager.mode, selectedDate.year, selectedDate.month)}
                                dailySpends={Object.values(getDataByDay(transactions, 'withdrawal', selectedDate.year, selectedDate.month, manager.mode)).map((transactions_day:any) => transactions_day.reduce((acc:any, curr:any) => acc + curr.amount, 0))}
                                totalDeposits={Object.values(getDataByDay(transactions, 'bank_transfer', selectedDate.year, selectedDate.month, manager.mode)).map((transactions_day:any) => transactions_day.reduce((acc:any, curr:any) => acc + curr.amount, 0))}
                                {...props}
                                />
                            : 
                                <div id="chart">
                                    <div id = "chart--nodata">
                                        <ChartBro />
                                        <p>{translate("no-data")}</p>
                                    </div>
                                </div>


                        }
                        

                        <div id="history" key = {"TRANSACTION-HISTORY"}>
                            <div className="history__title">
                                <Icons name = "listing-tree" {...icons_config} />
                                <h1>{translate("history")}</h1>
                                <div className="history__title--buttons">
                                    {
                                        /*
                                            <div
                                                onClick = {() => setEvents((state:any) => {return {...state, fullscreen : "TRANSACTION-HISTORY"}})}
                                            >
                                                <Icons name = "down-left-and-up-right-to-center" mode = "duotone" color = "grey" />
                                            </div>
                                        */
                                    }

                                </div>
                            </div>
                            <div className="history__container">
                                <Table {...table_config} />
                            </div>

                        </div>
                    </div>
                </BaseContainer>
            </BaseApp>
        </React.Fragment>
    )
}